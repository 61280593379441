
.twitter-picker-1 > div:nth-of-type(1) {
    /* CSS cho khối div đầu tiên */
    // border-style: none !important; /* Ẩn mũi tên mặc định của TwitterPicker */
    left: auto !important;
    right: 14px;
}

.twitter-picker-1 > div:nth-of-type(2) {
    /* CSS cho khối div cuối cùng */
    // border-style: none !important;
    right: 14px;
    left: auto !important;
}