// Home page 1.
.wrapper_HP{
    background-color: rgb(241, 241, 241);
    height: calc(100vh - 1rem);
    margin: 0.5rem;
    border-radius: 21px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: auto;
    padding: 9px
}
.bg-home{
    background: #9e95dcb0;
    padding: 21px;
    border-radius: 21px;
    height: calc(100vh - 9rem);
}

// home page mobile.
.homepagemobile{
    height: calc(100vh);
    position: relative;
}

.background-image {
    background-size: cover; /* Để ảnh phủ kín toàn bộ div */
    background-position: center; /* Để căn giữa ảnh */
    width: 100%;
    height: 200px; /* Đặt chiều cao của div thành 100% chiều cao viewport */
    //   background: linear-gradient(to bottom, rgba(252, 185, 0, 0.75), rgba(252, 185, 0, 0));
    // background: linear-gradient(to right, var(--primary-color) 0%, #0086ed 100%);
    background-color: #fdd5b9;
    position: absolute;
    z-index: -11;
}
// .background-image::before {
//     content: '';
//     position: absolute;
//     bottom: -30px; /* Điều chỉnh khoảng cách xuống */
//     left: 0;
//     width: 100%;
//     height: 60px; /* Điều chỉnh độ cao của độ võng */
//     background: linear-gradient(to right, var(--primary-color) 0%, #0086ed 100%);
//     border-radius: 50%;
//     box-shadow: 0 3px 6px 0px rgba(0, 0, 0, 0.2);
//   }
.listCateMobile{
  overflow-x: auto;
}
.listCateMobile::-webkit-scrollbar{
  display: none;
}