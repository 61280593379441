.path-card {
    width: 25px;
    position: relative;
    right: -1px;
    bottom: 3px;
}
.ts-card {
    text-anchor: middle;
    dominant-baseline: auto;
}
@keyframes draw {
  from {
    stroke-dashoffset: 100;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.path-card #ts-bc {
  animation: draw 2s ease-out forwards;
}