// Home data sale pc.
.wrapper_top{
  // position: sticky;
  // top: 0;
  // background: white;
  // z-index: 99;
  // padding-bottom: 6px;
  // border-bottom: 1px solid #f3f3f3;
  // width: inherit;
  background: var(--menu-bg);
  // border-inline-end: 1px solid var(--menu-border-color);
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  z-index: 19;
  transition: all 0.05s ease;
  width: 100%;
  height: 103px;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
}
.notifiCate{
  position: absolute;
  top: -5px;
  right: 0px;
  background: red;
  padding: 2px 6px;
  color: white;
  border-radius: 21px;
  font-size: 9px;
}
.list_cate a button{
  height: 33px;
  width: 100px;
}
.wrapper_listItem{
  width: 100%;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  margin-top: 138px;
}
.count-sticky{
  height: 30px;
  background: white;
  position: fixed;
  top: 103px;
  z-index: 11;
  width: 100%;
  padding: 0 1.25rem;
  inset-inline-start: 0;
  left: 50%;
  transform: translate(-50%, 0%);
}
.count-sticky div{
  height: 30px;
  display: flex;
  align-items: center;
}

/** sidebar content*/
.Sidebar_Content {
  display: flex;
  min-height: 100vh;
}
.Sidebar_Wrap {
  flex-shrink: 0;
}
.Sidebar_Wrapper {
  --width: 96px;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  left: 0;
  padding: 0 8px;
  position: -webkit-sticky;
  position: sticky;
  top: 114px;
  width: var(--width);
  z-index: 8;
}
.ListCategory {
  position: sticky;
  right: 0;
  top: 86px;
  z-index: 11;
  background: white;
  padding-bottom: 9px;
}
.AddProduct {
  align-items: center;
  // background-color: #3a77f8;
  // border-color: #1473e6;
  background: linear-gradient(to right bottom, #ff8f26, #ff5117);
  opacity: 0.86;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  // margin: 8px 0 2px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 40px;
}
.fs-33 {
  font-size: 33px;
}
.IconAdd {
  transition: transform 0.3s ease; /* Thêm hiệu ứng xoay mềm mại */
}
.IconAdd:hover {
  transition: transform 0.3s ease; /* Thêm hiệu ứng xoay mềm mại */
}

.IconAdd:hover {
  transform: rotate(46deg); /* Xoay 360 độ */
}
.Sidebar_list {
  padding-left: 0;
}
.Sidebar_item {
  align-items: center;
  background-color: #fff;
  border-radius: 16px;
  // color: var(--color-purple-pastel);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 72px;
  justify-content: center;
  margin-top: 4px;
  width: 72px;
}
.Sidebar_item:hover {
  background-color: #e8ebed;
  color: #1a1a1a;
}
.ContentPro {
  max-width: calc(100% - 96px);
  padding: 0 0px 0 86px;
}
/** *sidebar content*/


/** List Note data sale */
.bulkhead{
    background: #e0e0e0;
    border-radius: 9px;
    content: "";
    height: 60px;
    margin: 0px 6px;
    width: 3px;
}
.w-350{
  width: 350px;
}
.mw-1000{
  width: 1000px;
  overflow-x: auto;
  overflow-y: hidden;
}
.buttonAddNote{
  position: sticky;
  z-index: 9;
}
.h-41{
  min-height: 41px;
}


.container-item{
  border: 1px solid #eaeaea;
}
.container-item .item-a, .item-b{
  border-right: 1px solid #eaeaea;
  padding-left: 12px !important;
}
.bg-warning-tran{
  background-color: rgba(var(--warning-rgb),.1)!important;
}
.bg-danger-tran{
  background-color: rgba(var(--danger-rgb),.1)!important;
}
.buttonCate.active{
  background: linear-gradient(to right bottom,#ff8f26,#ff5117);
  color: white !important;
}
/** *List Note data sale */


/** Css trên mobile */
.listDataSaleMobile{
  background: #f0f0f0;
  padding: 12px;
  border-radius: 6px;
}
.listCateMobile{
  overflow-x: auto;
}
.listCateMobile::-webkit-scrollbar{
  display: none;
}
.itemCateMobile{
  height: 40px;
  display: flex;
  align-items: center;
  width: 121px;
  background: #efefef;
  justify-content: center;
  border-radius: 9px;
}
.itemCateMobile.active{
  background-color: #1473e6;
  color: white !important;
}
.notifi{
  background: #fb4b4b;
  padding: 0 6px;
  border-radius: 21px;
  font-size: 12px;
  color: white !important;
}
// button add.
.buttonAddDataSaleMMobile{
  bottom: 15px;
  position: fixed;
  z-index: 99;
  transform: translate(145px, 10px);
}
.buttonAddDataSaleMMobile .buttonIcon{
  background-color: #3a77f8bf;
  border-radius: 21px;
  padding: 3px;
  color: white;
  border: 2px solid white !important;
  font-size: 39px;
}
.active{
  background-color: #1473e6;
  color: white !important;
}
/** *Css trên mobile */

/** filter data */
.Search_Wrapper_horizontal{
    border: 2px solid #e8e8e8;
    flex: 1 1;
    height: 40px;
    padding: 0 16px 0 8px;
    transition: border-color .2s ease-in-out;
}
.SearchIcon{
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 18px;
    border-radius: 50%;
    // height: 38px;
    opacity: .7;
    transition: opacity .2s ease-in-out;
    width: 30px;
    transform: translate(10px, 0);
}
.Search_ClearText{
    cursor: pointer;
    padding: 4px 0 4px 4px;
    opacity: 0.7;
}
.Search_Input{
    border: none;
    caret-color: #444;
    flex: 1 1;
    height: 100%;
    outline: none;
    padding: 0 4px;
    background: transparent;
    font-size: 15px;
}
/** filter data */