/* Trong file CSS của bạn */
/* Ẩn border của ảnh trong slider */
.slick-slide img {
  border: none;
}
/* Loại bỏ border khi phần tử được focus */
*:focus {
  outline: none;
}

/* Định dạng chấm tròn */
.slick-dots {
  position: absolute;
  bottom: 20px; /* Điều chỉnh vị trí của chấm */
  width: 100%;
  text-align: center;
}

// /* Tùy chỉnh kiểu chấm */
// .slick-dots li {
//   display: inline-block;
//   margin: 0 5px;
//   padding: 0;
//   cursor: pointer;
// }

// /* Tùy chỉnh kích thước của chấm */
// .slick-dots li button {
//   font-size: 10px;
//   line-height: 1;
//   color: #000; /* Màu chấm */
//   opacity: 0.5; /* Độ mờ của chấm */
//   border: none; /* Loại bỏ border */
//   background: transparent; /* Loại bỏ nền */
//   padding: 0;
// }

// /* Tùy chỉnh kiểu chấm khi được chọn */
// .slick-dots li.slick-active button {
//   opacity: 1;
//   color: #007bff; /* Màu chấm khi được chọn */
// }

/* Ẩn văn bản và chỉ hiển thị các chấm tròn */
.slick-dots li button {
  font-size: 0; /* Ẩn văn bản */
  width: 10px; /* Độ rộng của chấm */
  height: 10px; /* Chiều cao của chấm */
  border-radius: 50%; /* Tạo hình tròn */
  background-color: #ffffff6f; /* Màu nền của chấm */
}
.slick-dots li button::before {
  font-size: 0; /* Ẩn văn bản */
  width: 10px; /* Độ rộng của chấm */
  height: 10px; /* Chiều cao của chấm */
  border-radius: 50%; /* Tạo hình tròn */
  background-color: #ffffff6f; /* Màu nền của chấm */
  border: none; /* Loại bỏ border */
}

/* Tùy chỉnh kiểu chấm khi được chọn */
.slick-dots li.slick-active button::before {
  background-color: #fdcf52; /* Màu nền của chấm khi được chọn */
}


/* Tùy chỉnh nút */
/* Trong file YourComponent.css */
.slider-container:hover .slick-prev,
.slider-container:hover .slick-next {
  display: block; /* Hiển thị nút "Next" và "Previous" khi hover vào Slider */
}

/* Ẩn nút "Next" và "Previous" khi không hover vào Slider */
.slider-container .slick-prev,
.slider-container .slick-next {
  display: none;
}


/** Slide banner */
.itemSlide{
  padding: 8px 0px 8px 12px;
}
.box-gift-card {
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    position: relative;
}
.box-gift-card::before {
    position: absolute;
    top: 33px;
    left: -15px;
    border-left: 18px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 12px solid #ffffff;
    content: "";
    transform: rotate(90deg);
}
.box-gift-card::after {
    position: absolute;
    top: 33px;
    right: -12px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 8px solid #ffffff;
    content: "";
    transform: rotate(269deg);
}
.box-gift-card .img-gift{
  height: 80px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
  width: 40%;
}
.bg-card{
  background-color: #f8f8f8;
}
.bg-card-warning{
  background-color: rgb(247 194 103) !important;
}
.img-gift img{
  border-left: 2px solid #ffffff;
}
.itemRight{
  padding: 8px 12px 8px 12px;
}
/** Slide banner */
