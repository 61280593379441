
.text-success-1{
    color: #03d55c;
}
.text-warning-1{
    color: #fed500;
}
.text-gray-1{
    color: #9e9e9e;
}
.position-sticky-top-60{
    position: sticky;
    top: 60px;
    background: white;
    padding: 6px 0;
    z-index: 19;
}
.text-than{
    color: #4d5b97
}

.rounded-left-4{
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}
.rounded-right-4{
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
}
.button-hover{
    transition: transform .3s ease;
}
.button-hover:hover{
    transform: rotate(45deg);
    color: #a03aae;
}
.react-datepicker__header {
   font-family: view360, sans-serif !important;
}
.react-datepicker__week{
   font-family: view360, sans-serif !important;
}
.react-datepicker-popper{
   max-width: 200px;
}
// Dùng chung
.w-20{
    width: 20%;
}
.w-80{
    width: 80%
}
.carousel-app::-webkit-scrollbar{
    overflow: hidden;
}

// dropdown-toggle;
.dropdown-toggle::after{
    content: "" !important;
}

// page Dashboard 1
.wrapper_right{
    height: calc(100vh - 0.5rem);
    overflow: auto;
}
.wrapper_container{
    height: 84vh;
}

// chỉnh width 100% cho thư viện lazyloadimages.
.lazy-load-image-background{
  width: 100%;
}
// Chỉnh ảnh ra giữa tâm
.slide-container-image{
  width: 100%;
  height: 600px; /* Chiều cao cố định cho container */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Đảm bảo không có phần dư của hình ảnh vượt ra ngoài container */
}

/** color */
.text-orange{
    color: rgb(245 129 73) !important;
}
.bg-orange{
    background: #FF6900;
}
/** *color */

// form.
.form-floating label{
 left: 3px !important;
}

// list suggest.
.list_suggest{
    position: absolute;
    z-index: 9;
    background: white;
    // width: 221px;
    border: 1px solid #d8d8d8;
    border-radius: 6px;
    box-shadow: 0 .25rem .625rem rgba(20, 20, 20, .1);
    inset: 0px 0px auto auto;
    margin: 0px;
    transform: translate3d(-12px, 61px, 0px);
}

.form-select, .form-control{
    font-size: 15px;
}

input[type="checkbox"]{
    width: 15px;
    height: 15px;
}

/**
*screen 576px cho cac dong di dong
* screen 768px cho cac dong ipad mini
* screen 1024px cho cac dong ipad pro
*/ 
@media screen and (max-width: 1026px) { 
    button {
        color: #27173e;
    }
    body {
        font-size: 1.125rem;
    }
    .dropdown-item{
        font-size: 1.125rem;
    }
    button .icon-svg{
        margin: -0.5rem;
        // margin-top: -1rem;
    }
    .button-scroll{
        overflow-x: scroll;
    }
    .button-scroll::-webkit-scrollbar{
        display: none;
    }
    h6{
        font-size: 1.125rem;
    }
    p{
        font-size: 17px;
    }
    .form-label{
        font-size: 0.9rem;
    }
    .form-control{
        font-size: 0.95rem;
        font-weight: 500 !important;
    }
    .wrapper_container_mobile{
        padding: 0 9px
    }
}

// aar overflow dùng cho thư viện react-virtualized
.ReactVirtualized__Grid__innerScrollContainer{
    overflow: visible !important;
    
}
//*aar overflow dùng cho thư viện react-virtualized

// .modal-open .modal-active{
//     padding-right: 1.5rem;
// }

// Css cho input check.
.rounded-checkbox {
    width: 20px !important;
    height: 20px !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 50%; /* Bo tròn góc */
    outline: none;
    cursor: pointer;
    position: relative;
    margin-top: 3px
}

.rounded-checkbox:checked {
    background-color: #2196F3;
    border-color: #2196F3;
}

.rounded-checkbox:checked::before {
    content: "";
    position: absolute;
    top: .1rem;
    left: 0.3rem;
    width: 0.4rem;
    height: 0.7rem;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}


// CHỉnh kích cỡ màn hình.
.wrapper_ground{
    display: flex;
    flex-wrap: wrap;
    gap: 9px;
}
.list_items{
    flex-grow: 1;
    flex-basis: 200;
}


// Css timeline.
.timeline-widget-1 {
    position: relative;
}
.timeline-widget-1:before {
    position: absolute;
    content: "";
    width: 0.125rem;
    height: 100%;
    background-color: #885fdf;
    inset-block-start: 0;
    inset-inline-start: 4rem;
}
.timeline-widget-1 .timeline-widget-list-1 {
    margin-block-end: 1.5rem;
    position: relative;
}
.timeline-widget-1 .timeline-widget-list-1:before {
    content: "";
    position: absolute;
    width: 0.75rem;
    height: 0.75rem;
    inset-inline-start: 3.688rem;
    top: 0.25rem;
    border-radius: 3.125rem;
    border: 0.125rem solid var(--default-border);
    background-color: #885fdf;
}
.timeline-width{
    width: 15%;
}

// Timeline :horizontal.
.timeline-hor {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
}

.timeline-hor::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #ddd;
    z-index: 1;
}

.timeline-event-hor {
    position: relative;
    width: 100px;
    text-align: center;
}

.timeline-event-hor::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-color: #3498db;
    border-radius: 50%;
    z-index: 2;
    border: 4px solid white;
}

.timeline-event-hor .event-date-hor {
    margin-bottom: 15px;
    font-weight: bold;
}

.timeline-event-hor .event-description-hor {
    margin-top: 15px;
    font-size: 14px;
}

.timeline-event-hor:hover::before {
    background-color: #e74c3c;
}



// icon button.
.btn-icon-1 {
    padding: 3px;
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    font-size: 0.7rem;
}
