.pro-phone{
    position: absolute;
    z-index: 9;
    background: #e8e8e8;
    padding: 6px;
    border-radius: 3px;
    transform: translate(10px, 10px);
}

.item-es{
    border: 1px solid #f1f2f5;
    border-radius: 21px;
    padding: 12px;
}

// form real estate fast.
.border-1{
    border:1px dashed #f3f3f3;
}
.file-input{
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
    height: 36px;
    width: 100%;
}

.option-button {
    position: relative;
    z-index: 0;
    // background-color: #3498db;
    color: #3498db;
    // padding: 10px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    height: 26px;
    line-height: 1;
    width: 100%;
    font-size: 26px;
}

/* file image */
#imageContainer {
    display: flex;
    flex-wrap: wrap;
}

.previewImage {
    width: 100%;
    height: 100px;
    margin: 5px;
    object-fit: cover;
    border-radius: 9px;
}
.deleteButtonImage {
    cursor: pointer;
    background-color: #ffffff;
    color: #ff4545;
    border: none;
    padding: 4px 4px;
    border-radius: 16px;
    position: absolute;
    bottom: 18px;
    left: 43%;
}
ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.listImage{
    position: relative;
}