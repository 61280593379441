/** === thuộc tính chung ===*/
.search-data{
    width: 36.4px;
    height: 36.4px;
    flex-grow: 0;
}
.active-search{
    width: 100% !important;
    transition: width 5s;
    
}
.search-data.active-search{
    
}

// Mô phỏng hướng.
.bac{
    transform: rotate(90deg);
}
.dong{
    transform: rotate(180deg);
}
.dong div{
    transform: scale(-1)
}

.nam{
    transform: rotate(-90deg);
}
.tay{
    transform: rotate(0deg);
}
.taynam{
    transform: rotate(-45deg);
}
.dongnam{
    transform: rotate(-135deg);
}

.dongnam div{
    transform: scale(-1)
}
.taybac{
    transform: rotate(45deg);
}
.dongbac{
    transform: rotate(135deg);
}
.dongbac div{
    transform: scale(-1);
}
.timduong{
    border-right: 2px solid #ff6565;
    width: 30px
}
.door-mt{
    position: absolute;
    top: 2px;
    left: -10px;
    background: white;
}

/** === *Thuộc tính chung ===*/


/** ===== css PC ===== */
.bg-container{
    background: #efefef;
    min-height: 100vh;
}
.bg-success-1{
    background: #05d55d !important;
}
.text-success-1{
    color: #3eca7f;
}
.bg-warning-1{
    background-color: #fdd600 !important;
    color: white;
}
.color-orange{
    color: #f86c23;
}
.bg-gray-1{
    width: 100%;
    color: white;
    background: #9b9b9b;
}
.color-orange::after{
    content: "";
    width: 100%;
    height: 3px;
    background: #f86c23;
    display: block;
    border-radius: 21px;
}
.color-gray{
    color: #a5a5a5;
}
// Header.
.wrapper_header{
    width: 100%;
    height: 58px;
    box-shadow: 3px 7px 6px -5px #c9c4c4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
}
.stickyCss{
    position: fixed;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 10px 0;
    top: 0;
    z-index: 21;
    transition: top .1s;
}
.cate_main{
    padding: 6px 9px;
}
.list_cate_job .active .cate_main{
    background: linear-gradient(to right bottom, #ff8f26, #ff5117);
    color: white !important;
    padding: 6px 9px;
    border-radius: 21px;
}
.list_cate{
    background-color: white;
    box-shadow: 3px 7px 6px -5px #c9c4c4;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}
// *Header.

.class_input{
    background: #dedede;
    padding: 6px 12px;
    border-radius: 3px;
    height: 36.4px;
    font-size: 18px;
}
.button_color_a{
    background: #3d7ed0;
    color: white;
    height: 36px;
    padding: 0 12px;
    font-weight: 500;
}
.button_color_b{
    background-color: #ec5405;
    color: white;
    height: 36px;
    padding: 0 12px;
    font-weight: 500;
}

// Bảng chấm công.
.horizontal-table {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.day-cell {
  text-align: center;
  margin: 0 10px;
}
.day-cell-1{
    border: 1px dashed #dbdbdb;
}

.day-name {
  font-weight: 500;
//   color: #485056;
}

.day-date {
  font-size: 0.9em;
  color: #485056;
  font-weight: 500;
}
.bg_today{
    background: linear-gradient(to right, rgb(var(--secondary-rgb)) 0%, #6789d8 100%);
    color: white !important;
}
.bg_today .day-name{
    color: white !important;
}
.bg_today .day-date{
    color: white !important;
}

.button_icon{
    background: #eaeaea;
    padding: 6px;
    border-radius: 21px;
    color: #f86c23;
}
.salary-table{
    border: 1px solid;
    padding: 3px;
}
.salary-table .left{
    border-right: 1px solid;
}
.border-s{
    border: 1px solid !important;
}
.avatarInput{
    position: absolute !important;
    top: 0;
    z-index: 9;
    right: 0;
    transform: translate(-10px, 20px);
}
/** ===== *css PC ===== */




/** ==== css mobile ====*/
.wrapper_ground{
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}
.wrapper_item{
    flex-grow: 1;
    flex-basis: 200;
    width: 59px;
    border: 1px solid #dddddd;
    border-radius: 9px;
    overflow: hidden;
}

.wrapper_item_1{
    flex-grow: 1;
    border: 1px solid #dddddd;
    border-radius: 9px;
    overflow: hidden;
}

// Bảng chấm công
.color-white{
    color: white !important;
}
.text-danger-date{
    color: #f56161;
}
.bg-danger-date{
    background-color: #f56161;
}
.bg-success-date{
    background-color: #f0f0f0;
    color: #bfbfbf;
}
.text-success-date{
    color: #3eca7f;
}
.bg-success-date-1{
    background-color: #97ce9a;
    color: white;
}
.text-success-date-1{
    color: #97ce9a;
}
.bg-warning-date{
    background-color: #f3c45e;
}
.text-warning-date{
    color: #f3c45e;
}
// *Bảng chấm công




// Mục chi phí
.icon_timeline_expense{
    position: absolute;
    width: 0.75rem;
    height: 0.75rem;
    inset-inline-start: 3.488rem;
    inset-block-start: 0.02rem;
}
.icon-timeline:before{
    width: 1.4rem !important;
    height: 1.4rem !important;
    inset-inline-start: 3.288rem !important;
}

// Css tài chính cá nhân
.background-image {
    background-size: cover; /* Để ảnh phủ kín toàn bộ div */
    background-position: center; /* Để căn giữa ảnh */
    width: 100%;
    height: 200px; /* Đặt chiều cao của div thành 100% chiều cao viewport */
    //   background: linear-gradient(to bottom, rgba(252, 185, 0, 0.75), rgba(252, 185, 0, 0));
    // background: linear-gradient(to right, var(--primary-color) 0%, #0086ed 100%);
    background: #fdd5b9;
    position: absolute;
    z-index: -1;
}
// .background-image::before {
//     content: '';
//     position: absolute;
//     bottom: -30px; /* Điều chỉnh khoảng cách xuống */
//     left: 0;
//     width: 100%;
//     height: 60px; /* Điều chỉnh độ cao của độ võng */
//     background: linear-gradient(to right, var(--primary-color) 0%, #0086ed 100%);
//     border-radius: 50%;
//     box-shadow: 0 3px 6px 0px rgba(0, 0, 0, 0.2);
//   }

.listCateMobile{
  overflow-x: auto;
}
.listCateMobile::-webkit-scrollbar{
  display: none;
}
// Css tài chính cá nhân



/** ==== * css mobile ====*/